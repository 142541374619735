export default (function (props, wrapperRef) {
  var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  // handler on change page
  var onChangePage = props.onChangePage,
    jumpToConfirmation = props.jumpToConfirmation;
  if (onChangePage) {
    onChangePage();
  }
  if (jumpToConfirmation) {
    var rect = wrapperRef ? wrapperRef.current.getBoundingClientRect() : false;
    if (rect && window) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: scrollTop + rect.top - 100 - offset
      });
    }
  }
});