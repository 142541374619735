export default (function (field, populatedFields, populatedEntry) {
  var value;
  var hasPopulatedEntry = false;
  var hasPopulation = field.inputName && populatedFields && populatedFields[field.inputName];
  if (populatedEntry && populatedEntry[field.id]) {
    hasPopulatedEntry = true;
  } else if (populatedEntry && field.inputs) {
    field.inputs.some(function (input) {
      if (populatedEntry[input.id]) {
        hasPopulatedEntry = true;
      }
    });
  }
  if (field.type === "checkbox") {
    value = field.choices.filter(function (choice) {
      return hasPopulation ? choice.value === populatedFields[field.inputName] : choice.isSelected;
    }).map(function (choice) {
      return choice.value;
    });
    if (hasPopulatedEntry) {
      value = field.inputs.filter(function (choice) {
        return populatedEntry[choice.id];
      }).map(function (choice) {
        return choice.label;
      });
    }
  } else if (field.type === "radio") {
    if (hasPopulation) {
      value = populatedFields[field.inputName];
    } else {
      var preselected = field.choices.find(function (choice) {
        return choice.isSelected;
      });
      value = preselected ? preselected.value : "";
    }
  } else if (field.type === "select") {
    var selectedOption = field.choices.filter(function (choice) {
      return hasPopulation ? choice.value === populatedFields[field.inputName] : choice.isSelected;
    }).map(function (item) {
      return {
        value: item.value,
        label: item.text
      };
    });
    value = selectedOption && selectedOption.length > 0 ? selectedOption[0] : "";
  } else {
    value = hasPopulation ? populatedFields[field.inputName] : hasPopulatedEntry ? populatedEntry[field.id] : field.defaultValue;
  }
  return value;
});