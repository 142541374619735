function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _readOnlyError(r) { throw new TypeError('"' + r + '" is read-only'); }
import { validateField } from "../validation";
import checkConditionalLogic from "./checkConditionalLogic";
export default (function (field, event, inputID, formValues, setFormValues, conditionalIds, conditionFields) {
  for (var _len = arguments.length, props = new Array(_len > 7 ? _len - 7 : 0), _key = 7; _key < _len; _key++) {
    props[_key - 7] = arguments[_key];
  }
  var onChange = props.onChange;
  var id = field.id,
    type = field.type,
    isRequired = field.isRequired;
  // Set new value
  var value;
  if (field.type === "checkbox") {
    var values = _toConsumableArray(formValues[field.id].value);
    var index = values.indexOf(event.target.value);
    if (index > -1) {
      values.splice(index, 1);
    } else {
      values.push(event.target.value);
    }
    value = values;
  } else if (field.type == "date" && field.dateType !== "datepicker") {
    var subId = field.subId,
      dateLabel = field.dateLabel;
    var _values = _toConsumableArray(formValues[field.id].value);
    _values[subId] = {
      val: event.target.value,
      label: dateLabel
    };
    value = _values;
  } else if (field.type === "consent") {
    value = event.target ? event.target.checked : "null";
  } else if (field.type === "address") {
    var _values2 = _objectSpread({}, formValues[field.id].value);
    if (inputID) {
      _values2[inputID] = event.target.value;
    }
    value = _values2;
  } else if (field.type === "postcode") {
    value = event.target ? event.target.value : null;
    Object.values(formValues).filter(function (item) {
      return item.cssClass === "field--street";
    })[0].value = event === null || event === void 0 ? void 0 : event.street;
    Object.values(formValues).filter(function (item) {
      return item.cssClass === "field--city";
    })[0].value = event === null || event === void 0 ? void 0 : event.city;
  } else if (field.type === "name") {
    var _values3 = _toConsumableArray(formValues[field.id].value);
    var _index = _values3.indexOf(inputID);
    if (_index > -1) {
      _values3.splice(_index, 1);
    } else {
      _values3[inputID] = event.target.value;
    }
    value = event.target.value;
  } else if (field.type === "password" || field.type === "email" && field.emailConfirmEnabled) {
    var _subId = field.subId;
    var _values4 = formValues[field.id] && formValues[field.id].value ? _toConsumableArray(formValues[field.id].value) : [];
    _values4[_subId] = {
      val: event.target.value
    };
    value = _values4;
  } else {
    value = event.target ? event.target.value : "null";
  }
  // if field is IBAN
  if (type === "text" && field.cssClass.indexOf("iban") > -1) {
    "iban", _readOnlyError("type");
  }

  // Validate field
  var valid = validateField(value, field);

  // if field ID is somewhere in conditional fields
  // recalculate all conditions
  if (conditionalIds.indexOf(id) !== -1) {
    formValues[id].value = value;
    for (var i = 0; i < conditionFields.length; i++) {
      var _id = conditionFields[i].id;
      var hide = checkConditionalLogic(conditionFields[i].conditionalLogic, formValues);
      formValues[_id].hideField = hide;
      if (hide) {
        if (formValues[_id].isRequired && hide) {
          formValues[_id].value = "";
        }
        formValues[_id].valid = !!formValues[_id].isRequired;
      }
    }
  }
  var newValues = _objectSpread(_objectSpread({}, formValues), {}, _defineProperty({}, id, {
    value: value,
    id: id,
    valid: valid,
    label: field.label,
    pageNumber: field.pageNumber,
    cssClass: field.cssClass,
    isRequired: field.isRequired
  }));
  setFormValues(_objectSpread({}, newValues));
  if (onChange) {
    onChange(newValues);
  }
});