export default (function (condition) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var rules = condition.rules,
    actionType = condition.actionType;
  if (!rules) return true;
  var formValues = fields || formValues;

  // show only if is selected "All fields" (it should be tweaked in future)
  // works only "show/hide when field is equal to"
  var hideField = actionType !== "hide";
  var hideBasedOnRules = [];
  for (var i = 0; i < rules.length; i++) {
    var _rules$i = rules[i],
      fieldId = _rules$i.fieldId,
      value = _rules$i.value,
      operator = _rules$i.operator;
    var conditionFieldValue = formValues[fieldId].value && formValues[fieldId].value.value ? formValues[fieldId].value.value : formValues[fieldId].value || false;
    var stringValue = Array.isArray(conditionFieldValue) ? conditionFieldValue.join("") : conditionFieldValue;

    // Check if comparision value is empty
    if (!value) {
      if (!stringValue && !value) {
        hideBasedOnRules[i] = actionType === "hide";
      } else {
        hideBasedOnRules[i] = actionType !== "hide";
      }
    } else if (stringValue && value === stringValue) {
      hideBasedOnRules[i] = actionType === "hide";
    } else if (stringValue && stringValue.includes(value)) {
      hideBasedOnRules[i] = actionType === "hide";
    } else {
      hideBasedOnRules[i] = actionType !== "hide";
    }

    // If operator is 'isnot' reverse value
    if (operator === "isnot") {
      hideBasedOnRules[i] = !hideBasedOnRules[i];
    }
  }
  hideField = hideBasedOnRules.every(function (i) {
    return i === true;
  });
  // formValues[id].hideField = hideField;
  // this.setState({ formValues });
  return hideField;
});