function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Force validate current page fields
 * @param {number} page
 */

var forceValidation = function forceValidation(page, formValues, setShowPageValidationMsg, setTouched) {
  // check if current oage has required fields
  var notValid = page ? Object.keys(formValues).some(function (x) {
    return formValues[x].pageNumber === page && !formValues[x].hideField && formValues[x].valid;
  }) : Object.keys(formValues).some(function (x) {
    return !formValues[x].hideField && formValues[x].valid;
  });
  if (notValid) {
    // make all fields of current page to be touched to trigger field validation
    var fields = page ? Object.keys(formValues).filter(function (x) {
      return formValues[x].pageNumber === page;
    }) : Object.keys(formValues);
    setTouchedFields(fields, setShowPageValidationMsg, setTouched);
    return false;
  }
  return true;
};

/**
 * Make fields of current page touched
 * if non multipages form - make all fields touched
 * @param {array} fields
 * @param {fn} setShowPageValidationMsg
 * @param {fn} setTouched
 */
function setTouchedFields(fields, setShowPageValidationMsg, setTouched) {
  var currentPageTouched = fields.reduce(function (currentTouched, x) {
    currentTouched = _objectSpread(_objectSpread({}, currentTouched), {}, _defineProperty({}, x, true));
    return currentTouched;
  }, {});
  setShowPageValidationMsg(true);
  setTouched(currentPageTouched);
}
export default forceValidation;