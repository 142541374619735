export var newColorsRgb = {
  "RAL 1000": "197,187,138",
  "RAL 1001": "198,178,134",
  "RAL 1002": "199,174,114",
  "RAL 1003": "230,176,025",
  "RAL 1004": "210,164,014",
  "RAL 1005": "188,150,017",
  "RAL 1006": "207,152,004",
  "RAL 1007": "212,147,000",
  "RAL 1011": "163,132,084",
  "RAL 1012": "207,181,057",
  "RAL 1013": "223,219,199",
  "RAL 1014": "212,199,156",
  "RAL 1015": "222,211,182",
  "RAL 1016": "232,226,083",
  "RAL 1017": "228,175,086",
  "RAL 1018": "235,211,070",
  "RAL 1019": "156,145,123",
  "RAL 1020": "153,145,103",
  "RAL 1021": "229,192,000",
  "RAL 1023": "230,190,005",
  "RAL 1024": "173,148,081",
  "RAL 1026": "255,255,000",
  "RAL 1027": "153,132,032",
  "RAL 1028": "242,165,000",
  "RAL 1032": "207,168,030",
  "RAL 1033": "228,160,045",
  "RAL 1034": "217,161,086",
  "RAL 1035": "137,130,113",
  "RAL 1036": "116,099,065",
  "RAL 1037": "219,154,023",
  "RAL 2000": "199,117,015",
  "RAL 2001": "167,077,035",
  "RAL 2002": "172,055,033",
  "RAL 2003": "225,124,048",
  "RAL 2004": "204,086,008",
  "RAL 2005": "255,035,001",
  "RAL 2007": "255,173,025",
  "RAL 2008": "214,108,033",
  "RAL 2009": "201,086,013",
  "RAL 2010": "188,096,045",
  "RAL 2011": "207,116,033",
  "RAL 2012": "194,103,079",
  "RAL 2013": "130,065,040",
  "RAL 3000": "150,042,039",
  "RAL 3001": "143,030,036",
  "RAL 3002": "141,031,036",
  "RAL 3003": "124,013,036",
  "RAL 3004": "101,025,039",
  "RAL 3005": "086,030,039",
  "RAL 3007": "061,035,038",
  "RAL 3009": "100,055,048",
  "RAL 3011": "110,033,036",
  "RAL 3012": "183,133,110",
  "RAL 3013": "138,047,040",
  "RAL 3014": "188,111,114",
  "RAL 3015": "204,158,164",
  "RAL 3016": "150,061,047",
  "RAL 3017": "185,083,091",
  "RAL 3018": "182,060,073",
  "RAL 3020": "171,021,025",
  "RAL 3022": "190,105,084",
  "RAL 3024": "248,000,000",
  "RAL 3026": "254,000,000",
  "RAL 3027": "158,027,060",
  "RAL 3028": "185,039,038",
  "RAL 3031": "151,050,056",
  "RAL 3032": "102,025,037",
  "RAL 3033": "148,053,045",
  "RAL 4001": "124,091,128",
  "RAL 4002": "130,058,075",
  "RAL 4003": "182,090,136",
  "RAL 4004": "095,024,055",
  "RAL 4005": "116,099,149",
  "RAL 4006": "133,046,111",
  "RAL 4007": "068,038,060",
  "RAL 4008": "124,071,125",
  "RAL 4009": "149,131,143",
  "RAL 4010": "172,059,113",
  "RAL 4011": "104,092,128",
  "RAL 4012": "103,101,122",
  "RAL 5000": "053,073,107",
  "RAL 5001": "041,071,099",
  "RAL 5002": "025,050,120",
  "RAL 5003": "032,049,081",
  "RAL 5004": "030,034,044",
  "RAL 5005": "019,074,133",
  "RAL 5007": "070,101,137",
  "RAL 5008": "047,058,068",
  "RAL 5009": "054,088,117",
  "RAL 5010": "014,069,122",
  "RAL 5011": "034,044,062",
  "RAL 5012": "069,127,179",
  "RAL 5013": "033,047,081",
  "RAL 5014": "102,118,145",
  "RAL 5015": "049,114,173",
  "RAL 5017": "015,081,138",
  "RAL 5018": "071,132,141",
  "RAL 5019": "038,086,130",
  "RAL 5020": "017,062,077",
  "RAL 5021": "033,109,118",
  "RAL 5022": "040,044,088",
  "RAL 5023": "077,100,138",
  "RAL 5024": "108,141,170",
  "RAL 5025": "060,099,121",
  "RAL 5026": "027,043,077",
  "RAL 6000": "074,115,099",
  "RAL 6001": "064,105,058",
  "RAL 6002": "059,091,047",
  "RAL 6003": "079,085,062",
  "RAL 6004": "033,066,069",
  "RAL 6005": "035,066,053",
  "RAL 6006": "060,061,050",
  "RAL 6007": "046,053,038",
  "RAL 6008": "051,051,039",
  "RAL 6009": "042,055,044",
  "RAL 6010": "078,110,057",
  "RAL 6011": "106,124,091",
  "RAL 6012": "047,059,057",
  "RAL 6013": "119,118,089",
  "RAL 6014": "069,067,057",
  "RAL 6015": "060,063,056",
  "RAL 6016": "037,103,083",
  "RAL 6017": "092,129,068",
  "RAL 6018": "104,154,069",
  "RAL 6019": "184,207,173",
  "RAL 6020": "059,070,052",
  "RAL 6021": "137,155,121",
  "RAL 6022": "059,056,046",
  "RAL 6024": "058,130,088",
  "RAL 6025": "093,112,062",
  "RAL 6026": "013,089,081",
  "RAL 6027": "136,181,179",
  "RAL 6028": "061,085,071",
  "RAL 6029": "034,108,069",
  "RAL 6032": "065,126,087",
  "RAL 6033": "086,132,128",
  "RAL 6034": "134,169,173",
  "RAL 6035": "046,079,049",
  "RAL 6036": "039,081,074",
  "RAL 6037": "063,140,061",
  "RAL 6038": "000,187,045",
  "RAL 7000": "123,133,141",
  "RAL 7001": "139,148,155",
  "RAL 7002": "125,121,101",
  "RAL 7003": "118,119,106",
  "RAL 7004": "150,151,153",
  "RAL 7005": "105,109,107",
  "RAL 7006": "113,108,096",
  "RAL 7008": "108,096,064",
  "RAL 7009": "091,096,088",
  "RAL 7010": "087,091,087",
  "RAL 7011": "083,090,094",
  "RAL 7012": "089,094,096",
  "RAL 7013": "084,081,070",
  "RAL 7015": "081,083,090",
  "RAL 7016": "059,064,068",
  "RAL 7021": "050,053,055",
  "RAL 7022": "076,076,071",
  "RAL 7023": "125,127,118",
  "RAL 7024": "069,073,078",
  "RAL 7026": "057,067,069",
  "RAL 7030": "140,140,131",
  "RAL 7031": "093,103,109",
  "RAL 7032": "177,177,161",
  "RAL 7033": "124,130,115",
  "RAL 7034": "140,136,112",
  "RAL 7035": "194,198,195",
  "RAL 7036": "148,146,146",
  "RAL 7037": "121,123,123",
  "RAL 7038": "173,176,169",
  "RAL 7039": "104,103,095",
  "RAL 7040": "150,156,161",
  "RAL 7042": "140,145,144",
  "RAL 7043": "079,083,082",
  "RAL 7044": "179,178,169",
  "RAL 7045": "140,144,148",
  "RAL 7046": "124,130,135",
  "RAL 7047": "197,197,197",
  "RAL 7048": "122,120,113",
  "RAL 8000": "129,109,068",
  "RAL 8001": "143,104,051",
  "RAL 8002": "112,079,064",
  "RAL 8003": "116,080,047",
  "RAL 8004": "129,077,055",
  "RAL 8007": "103,073,047",
  "RAL 8008": "105,079,043",
  "RAL 8011": "083,058,041",
  "RAL 8012": "092,049,040",
  "RAL 8014": "069,055,041",
  "RAL 8015": "087,051,043",
  "RAL 8016": "072,048,038",
  "RAL 8017": "066,051,046",
  "RAL 8019": "059,055,054",
  "RAL 8022": "032,031,032",
  "RAL 8023": "150,093,051",
  "RAL 8024": "111,084,060",
  "RAL 8025": "110,091,075",
  "RAL 8028": "076,062,048",
  "RAL 8029": "118,069,055",
  "RAL 9001": "229,225,212",
  "RAL 9002": "212,213,205",
  "RAL 9003": "235,236,234",
  "RAL 9004": "047,049,051",
  "RAL 9005": "019,021,022",
  "RAL 9006": "154,157,157",
  "RAL 9007": "130,130,128",
  "RAL 9010": "239,238,229",
  "RAL 9011": "037,040,042",
  "RAL 9016": "239,240,235",
  "RAL 9017": "038,038,037",
  "RAL 9018": "198,203,198",
  "RAL 9022": "129,131,130",
  "RAL 9023": "118,119,121"
};