var _getPrevStep = function getPrevStep(activePage, pages, formValues) {
  var prevPage = activePage - 1;
  var prevPageId = pages[activePage - 3] || 0;

  // if there is conditional login
  if (formValues[prevPageId] && formValues[prevPageId].hideField === true) {
    prevPage = _getPrevStep(prevPage);
  }
  return prevPage;
};
export default _getPrevStep;