function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var getMessage = function getMessage(message, type) {
  if (!type) return false;
  if (message && _typeof(message) === "object" && message.custom) {
    return message.custom;
  }
  if (message && _typeof(message) === "object" && message[type]) {
    return message[type];
  }
  return message;
};
var isEmail = function isEmail(email, field, message) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    var customMessage = getMessage(message, "email");
    return customMessage || "Enter a valid email";
  }
  return false;
};
var isUrl = function isUrl(str, field, message) {
  var pattern = new RegExp("^(https?:\\/\\/)?" +
  // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
  // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" +
  // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
  // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" +
  // query string
  "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
  if (!pattern.test(str)) {
    var customMessage = getMessage(message, "url");
    return customMessage || "Enter a valid url";
  }
  return false;
};
var isEmpty = function isEmpty(value) {
  if (!value) {
    return true;
  }
  return false;
};
var isRequired = function isRequired(required, empty, message) {
  if (required && empty) {
    var customMessage = getMessage(message, "required");
    return customMessage || "This field is required";
  }
  return false;
};
var selectValidation = function selectValidation(required, value, placeholder) {
  return !(value === placeholder && required);
};
var checkboxValidation = function checkboxValidation(values, message) {
  if (values.length < 1) {
    return message || "This field is required";
  }
  return false;
};
var emailValidation = function emailValidation(values, field) {
  var _ref = field || false,
    inputs = _ref.inputs,
    required = _ref.isRequired,
    errorMessage = _ref.errorMessage;
  var _ref2 = errorMessage || false,
    requiredMsg = _ref2.required,
    mismatch = _ref2.mismatch;

  // check if fields is required and isn't empty
  var isInputsEmpty = values && values.filter(function (item) {
    return item && item.val === "";
  }).length;
  if ((values && values.length < 2 || isInputsEmpty !== 0) && required) {
    return requiredMsg || "This field is required";
  }
  if (values && values.length > 0) {
    for (var i = 0; values.length > i; i++) {
      var isInValidMail = isEmail(values[i].val);
      if (isInValidMail) {
        return isInValidMail;
      }
    }
  }

  // if there is repeat password field => check if match
  if (values && values.length === 2 && inputs && inputs.length === 2) {
    if (values[1] && values[0] && values[1].val !== "" && values[1].val !== values[0].val) {
      return mismatch || "Mismatch";
    }
  }
  return false;
};
var passwordValidation = function passwordValidation(values, field) {
  var _ref3 = field || false,
    inputs = _ref3.inputs,
    required = _ref3.isRequired,
    errorMessage = _ref3.errorMessage;
  var _ref4 = errorMessage || false,
    requiredMsg = _ref4.required,
    mismatch = _ref4.mismatch;
  var filteredInputs = inputs.filter(function (item) {
    return !item.isHidden;
  });

  // check if fields is required and isn't empty
  var isInputsEmpty = values && values.filter(function (item) {
    return item && item.val === "";
  }).length;
  if ((values && values.length === 0 || isInputsEmpty === filteredInputs.length) && required) {
    return requiredMsg || "This field is required";
  }

  // if there is repeat password field => check if match
  if (values && values.length === 2 && filteredInputs && filteredInputs.length === 2) {
    if (values[1] && values[0] && values[1].val !== "" && values[1].val !== values[0].val) {
      return mismatch || "Mismatch";
    }
  }
  return false;
};
var isPostcode = function isPostcode(postcode, field, message) {
  var re = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
  if (!re.test(postcode)) {
    var customMessage = getMessage(message, "email");
    return customMessage || "Enter a valid postcode";
  }
  return false;
};
var isDate = function isDate(values, field) {
  var validation = [];
  for (var i = 0; i < values.length; i++) {
    if (values[i]) {
      var _values$i = values[i],
        val = _values$i.val,
        label = _values$i.label;
      if (val) {
        if (label === "MM") {
          var message = field.errorMessage["month"];
          var max = 12;
          var min = 1;
          var maxLength = 2;
          if (val.length > maxLength || val < min || val > max) {
            validation[i] = {
              index: i,
              message: getMessage(message, "date") || "Enter a valid month"
            };
          }
        } else if (label === "DD") {
          var _message = field.errorMessage["date"];
          var _max = 31;
          var _min = 1;
          var _maxLength = 2;
          if (val.length > _maxLength || val < _min || val > _max) {
            validation[i] = {
              index: i,
              message: getMessage(_message, "date") || "Enter a valid date"
            };
          }
        } else if (label === "YYYY") {
          var _message2 = field.errorMessage["year"];
          var _max2 = new Date().getFullYear() + 1;
          var _min2 = 1920;
          var _maxLength2 = 4;
          if (val.length > _maxLength2 || val < _min2 || val > _max2) {
            validation[i] = {
              index: i,
              message: getMessage(_message2, "date") || "Enter a valid year"
            };
          }
        }
      }
    }
  }
  return validation;
};
var isAddress = function isAddress(address, field, message) {
  var showError = !address.includes("\n");
  if (!showError) {
    var splitEachLine = address.split("\n");
    if (!!splitEachLine && Array.isArray(splitEachLine)) {
      for (var lineNr in splitEachLine) {
        if (splitEachLine[lineNr].length < 1) {
          showError = true;
        }
      }
    }
  }
  if (showError) {
    var customMessage = getMessage(message, "required");
    return customMessage || "This field is required";
  }
  return false;
};
var isPhoneNumber = function isPhoneNumber(phoneNumber, field, message) {
  var phoneNumberPattern = /^[\d()\-+\s]+$/;
  if (!phoneNumberPattern.test(phoneNumber) || phoneNumber.length < 10) {
    var customMessage = getMessage(message, "phone");
    return customMessage || "Enter a valid phone number";
  }
  return false;
};
var validateField = function validateField(value, field) {
  var orderFormCheck = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var type = field.type,
    required = field.isRequired;
  // Check first if requried checkbox or radio
  var message = field && field.errorMessage ? field.errorMessage : false;
  if ((type === "checkbox" || type === "radio") && required) {
    return checkboxValidation(value, field.errorMessage);
  }
  if (type === "password") {
    return passwordValidation(value, field);
  }
  if (type === "email" && field.emailConfirmEnabled) {
    return emailValidation(value, field);
  }
  if (type === "phone") {
    return isPhoneNumber(value, field, message);
  }
  if (type === "textarea" && (field.id === 8 || field.id === 18 || field.id === 34)) {
    return isAddress(value, field, message);
  }

  // Check if empty
  var empty = isEmpty(value);
  var validationMessage = "";
  // Set validation message if required
  validationMessage = required ? isRequired(required, empty, message) : false;
  // Set other validation messages
  if (!validationMessage && !empty) {
    if (type === "email") {
      validationMessage = isEmail(value, field, message);
    } else if (type === "website") {
      validationMessage = isUrl(value, field, message);
    } else if (type === "date") {
      var isValid = true;
      if (field.dateType && field.dateType === "datepicker") {
        isValid = required ? isRequired(required, empty, message) : false;
      } else {
        isValid = isDate(value, field);
      }
      validationMessage = isValid.length > 0 ? isValid : false;
    } else if (type === "postcode") {
      validationMessage = isPostcode(value, field, message);
    }
  }
  return validationMessage;
};
export { isEmpty, selectValidation, checkboxValidation, isUrl, isEmail, isRequired, validateField };