var getFields = function getFields(props) {
  return [{
    name: "typeofspace",
    type: "radio",
    title: props["title_typeofspace"],
    description: props["typeofspace_description"],
    choices: props["typeofspace_choices"]
  }, {
    name: "positioning",
    type: "radio",
    title: props["title_positioning"],
    description: props["positioning_description"],
    popup: "positioning",
    popupBtn: props["positioning_popup_start_btn"],
    popupContent: props["positioning_popup_content"],
    choices: props["positioning_choices"]
  }, {
    name: "rotation",
    type: "radio",
    title: props["title_rotation"],
    description: props["rotation_description"],
    choices: props["rotation_choices"]
  }, {
    name: "finishing",
    type: "color",
    title: props["title_finishing"],
    description: props["finishing_description"],
    popup: "finishing",
    popupBtn: props["finishing_popup_start_btn"],
    popupColorContent: props["finishing_popup_content"],
    choices: props["finishing_choices"],
    allColors: props["colors"],
    popupContent: props["colors_blocks"],
    specialColors: props["colors_blocks_special"],
    showAllText: props["show_all_text"],
    allColorsText: props["all_colors_text"],
    applyCloseText: props["apply_close_text"],
    primaryColorTitle: props["title_colors"],
    primaryColorDesc: props["description_colors"]
  }, {
    type: "content",
    title: props["instruction_title"],
    description: props["instruction_description"],
    instructionPopupTitle: props["instruction_popup_instruction"],
    videoPopupTitle: props["instruction_popup_video"],
    videoPopupUrl: props["instruction_popup_video_url"],
    popup: "instruction"
  }, {
    name: "dimensions",
    type: "number",
    title: props["title_dimensions"],
    description: props["dimensions_description"],
    popup: "detailed-dimensions",
    popupBtn: props["detailed_popup_start_btn"],
    popupContent: props["detailed_popup_content"],
    popupDesc: props["detailed_popup_desc"],
    popupOptions: props["detailed_popup_option"],
    choices: [{
      fields: props["height_settings"],
      fieldName: "height",
      shapeDiverName: "Hoogte",
      calcErrorInput: "Hoogte"
    }, {
      fields: props["width_settings"],
      fieldName: "width",
      shapeDiverName: "Breedte trap",
      calcErrorInput: "Breedte_Trap"
    }, {
      fields: props["depth_settings"],
      fieldName: "depth",
      shapeDiverName: "Diepte trap",
      calcErrorInput: "Diepte_Trap"
    }, {
      fields: props["depth_stairwell_settings"],
      fieldName: "depth_stairwell",
      shapeDiverName: "Diepte trapgat",
      calcErrorInput: "Trapgat_Diepte"
    }, {
      fields: props["width_stairwell_settings"],
      fieldName: "width_stairwell",
      shapeDiverName: "Breedte trapgat",
      calcErrorInput: "Trapgat_Breedte"
    }, {
      fields: props["depth_base_settings"],
      fieldName: "depth_base",
      shapeDiverName: "Verdiepte vloerplaat",
      calcErrorInput: "Verdiepte_Voetplaat"
    }, {
      fields: props["mounting_hole_height_settings"],
      fieldName: "mounting_hole_height",
      shapeDiverName: "Hoogte montagegaten",
      calcErrorInput: "Hoogte_Montagegaten"
    }, {
      fields: props["floor_thickness_settings"],
      fieldName: "floor_thickness",
      shapeDiverName: "Dikte verdiepingsvloer",
      calcErrorInput: "Dikte_Verdiepingsvloer"
    }]
  }, {
    name: "recommend",
    type: "recommend",
    title: props["title_recommend"],
    description: props["recommend_description"],
    choices: [{
      title: props["recommend_option_title"],
      description: props["recommend_option_description"],
      icon: false
    }, {
      title: props["recommend_manual_title"],
      description: props["recommend_manual_description"],
      icon: false
    }],
    SDchoices: [{
      SDfield: "Recommended by EeStairs",
      newValue: true
    }, {
      SDfield: "Recommended by EeStairs",
      newValue: false
    }],
    recommendDescription: props["recommend_option_list"]
  }, {
    name: "riserbars",
    type: "radio",
    title: props["title_riserbars"],
    description: props["riserbars_description"],
    popup: "riserbars",
    popupBtn: props["riserbars_popup_start_btn"],
    popupContent: props["riserbars_popup_content"],
    popupDesc: props["riserbars_popup_desc"],
    popupOptions: props["riserbars_popup_option"],
    choices: props["riserbars_choices"]
  }, {
    name: "plugs",
    type: "radio",
    title: props["title_plugs"],
    description: props["plugs_description"],
    popup: "antiskidplugs",
    popupBtn: props["antiplugs_popup_start_btn"],
    popupContent: props["antiplugs_popup_content"],
    popupDesc: props["antiplugs_popup_desc"],
    popupOptions: props["antiplugs_popup_option"],
    choices: props["plugs_choices"]
  }, {
    name: "accessibility",
    type: "radio",
    title: props["title_accessibility"],
    description: props["accessibility_description"],
    popup: "accessibility",
    popupBtn: props["accessibility_popup_start_btn"],
    popupContent: props["accessibility_popup_content"],
    popupDesc: props["accessibility_popup_desc"],
    popupOptions: props["accessibility_popup_option"],
    choices: props["accessibility_choices"]
  }, {
    name: "topstep",
    type: "radio",
    title: props["title_topstep"],
    description: props["topstep_description"],
    popup: "topstep",
    popupBtn: props["topstep_popup_start_btn"],
    popupContent: props["topstep_popup_content"],
    popupDesc: props["topstep_popup_desc"],
    popupOptions: props["topstep_popup_option"],
    choices: props["topstep_choices"]
  }, {
    name: "typehandrail",
    type: "radio",
    title: props["title_typehandrail"],
    description: props["typehandrail_description"],
    popup: "handrail",
    popupBtn: props["handrail_popup_start_btn"],
    popupContent: props["handrail_popup_content"],
    popupDesc: props["handrail_popup_desc"],
    popupOptions: props["handrail_popup_option"],
    choices: props["typehandrail_choices"]
  }, {
    name: "voidrailings",
    type: "void",
    title: props["title_voidrailings"],
    description: props["voidrailings_description"],
    popup: "voidrail",
    popupBtn: props["voidrail_popup_start_btn"],
    popupInfoContent: props["voidrail_popup_content"],
    popupDesc: props["voidrail_popup_desc"],
    popupOptions: props["voidrail_popup_option"],
    choices: props["voidrailings_choices"],
    popupContent: props["voidrailings_popup"]
  }, {
    type: "content",
    title: props["installation_title"],
    description: props["installation_description"],
    instructionPopupTitle: props["installation_popup_instruction"],
    videoPopupTitle: props["installation_popup_video"],
    videoPopupUrl: props["installation_popup_video_url"],
    popup: "installation"
  }, {
    name: "services",
    type: "radio",
    title: props["title_services"],
    description: props["services_description"],
    popup: "services",
    popupBtn: props["services_popup_start_btn"],
    popupContent: props["services_popup_content"],
    popupDesc: props["services_popup_desc"],
    popupOptions: props["services_popup_option"],
    choices: props["services_choices"]
  }, {
    name: "productiontime",
    type: "radio",
    title: props["title_productiontime"],
    description: props["productiontime_description"],
    popup: "productiontimeblock",
    popupBtn: props["ptb_popup_start_btn"],
    popupContent: props["ptb_popup_content"],
    popupDesc: props["ptb_popup_desc"],
    popupOptions: props["ptb_popup_option"],
    choices: props["productiontime_choices"],
    priceType: "percentage"
  }, {
    name: "weight"
  }, {
    name: "countPlugs"
  }, {
    name: "emission"
  }];
};
var getFieldsInOrder = function getFieldsInOrder(props) {
  var checkBoxGroupFields = ["riserbars", "plugs", "accessibility", "topstep", "voidrailings"];
  if (props !== 0) {
    var _getFieldsNames;
    var getFieldsNames = getFields(props).filter(function (field) {
      return field.hasOwnProperty("name");
    });
    var dimensionsNames = [];

    // Extract dimension-related fields
    getFieldsNames.filter(function (field) {
      return field.name.includes("dimensions");
    })[0].choices.forEach(function (choice) {
      dimensionsNames.push(choice.fieldName);
    });

    // Map to get the list of field names
    getFieldsNames = getFieldsNames.map(function (field) {
      return field.name;
    });

    // Insert dimension names after the "dimensions" field
    (_getFieldsNames = getFieldsNames).splice.apply(_getFieldsNames, [getFieldsNames.indexOf("dimensions") + 1, 0].concat(dimensionsNames));

    // Filter out the checkbox fields from their original positions
    getFieldsNames = getFieldsNames.filter(function (name) {
      return !checkBoxGroupFields.includes(name);
    });

    // Add "Included options" as a separate entry in the order
    getFieldsNames.push("Included options");
    return getFieldsNames;
  }
};
export { getFields, getFieldsInOrder };