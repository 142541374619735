function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import PropTypes from "prop-types";
var unsetError = function unsetError(id, errorMessages) {
  if (!errorMessages) return;
  if (errorMessages) {
    if (_typeof(errorMessages) === "object" && errorMessages[id]) {
      delete errorMessages[id];
    }
  }
};
export default unsetError;
unsetError.propTypes = {
  id: PropTypes.number.isRequired,
  errorMessages: PropTypes.func
};