function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import React from "react";
import parse from "html-react-parser";
var formatPrice = function formatPrice(price, scheme, language) {
  var _locales$language;
  var discount = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var numberOnly = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var prefix = discount ? "- " : "";
  var locales = {
    UK: "en-UK",
    US: "en-US",
    CA: "en-CA",
    CH: "fr-CH",
    FR: "fr-FR"
  };
  var activeLocale = (_locales$language = locales[language]) !== null && _locales$language !== void 0 ? _locales$language : "nl-NL";
  price = typeof price === "string" ? parseFloat(price) : typeof price === "undefined" ? 0 : price;
  var formattedPrice = price.toLocaleString(activeLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return price ? numberOnly ? formattedPrice : prefix + scheme[0][language] + " " + formattedPrice : 0;
};
var getPriceLabel = function getPriceLabel(price, scheme, type, language) {
  var unit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var totalPrice = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
  var convertPercentageToPrice = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  var output;
  switch (type) {
    case "single":
      output = formatPrice(price, scheme, language);
      break;
    case "percentage":
      var percentage = price;
      if (convertPercentageToPrice) {
        if (totalPrice) {
          output = formatPrice(totalPrice * (percentage / 100), scheme, language);
        }
      } else {
        output = "".concat(percentage, "%");
      }
      break;
    case "monthly":
      var unitLabel = unit === "mm" ? "m" : unit;
      // One inch is 0.0254 meters
      !!price && unitLabel === "inch" ? price = (parseFloat(price) * 0.0254).toFixed(2) : price;
      output = "".concat(formatPrice(price, scheme, language), " / ").concat(unitLabel);
      break;
  }
  return output;
};
var getPriceByField = function getPriceByField(field, type, scheme, returnType, language, designOnly) {
  var _Object$entries, _price;
  var unit = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  var price;
  (_Object$entries = Object.entries(scheme)) === null || _Object$entries === void 0 || _Object$entries.map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (value["materiaal"] === field) {
      price = scheme[key][language];
    }
  });
  if (returnType === "price") return (_price = price) !== null && _price !== void 0 ? _price : "0";
  return "".concat(designOnly ? "" : "+ ").concat(getPriceLabel(price, scheme, type, language, unit ? unit : false));
};
var getKeyByValue = function getKeyByValue(array, key, searchValue) {
  if (!array) return;
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] === searchValue) {
      return i;
    }
  }
};
var getPriceTotal = function getPriceTotal(costs, scheme, language) {
  var formatThePrice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var design = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var excludePercentage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var discount = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 0;
  var paymentSpread = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
  var paymentSpreadPrice = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : 0;
  var priceRowStandard = getKeyByValue(scheme, "materiaal", "Standard price");
  var total = 0;
  if (!design) {
    total += parseInt(scheme[priceRowStandard][language]) || 0; // Dit is total price
  }
  var percentage = null;
  if (design) {
    if (!!costs) {
      var toNumber = parseInt(costs[Object.keys(costs)[0]]);
      if (costs.type === "percentage") {
        percentage = toNumber;
      } else {
        total += toNumber;
      }
    }
  }
  if (!!costs && !design) {
    var _Object$keys;
    (_Object$keys = Object.keys(costs)) === null || _Object$keys === void 0 || _Object$keys.map(function (key, value) {
      if (Object.values(costs[key]).length > 0) {
        if (key === "paymentSpread") return;
        var _toNumber = parseInt(Object.values(costs[key])[0]);
        if (costs[key]["type"] === "percentage") {
          percentage = _toNumber;
        } else {
          total += _toNumber;
        }
      }
    });
  }
  if (!!paymentSpread) {
    total += total * paymentSpreadPrice / 100;
  }
  if (!!discount && discount > 0) {
    total -= discount;
  }
  total = !!percentage && !excludePercentage ? total * (percentage / 100 + 1) : total;
  return formatThePrice ? formatPrice(total, scheme, language) : total;
};
var getPriceDetails = function getPriceDetails(costs, scheme, language, allDataFields) {
  var designOpt = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var designOptRef = arguments.length > 5 ? arguments[5] : undefined;
  var standardText = arguments.length > 6 ? arguments[6] : undefined;
  var tableData = [];
  var langCode;
  if (_typeof(language) === "object") {
    langCode = language.language;
  } else {
    langCode = language;
  }
  if (!!costs) {
    var _Object$keys2;
    (_Object$keys2 = Object.keys(costs)) === null || _Object$keys2 === void 0 || _Object$keys2.map(function (key) {
      if (Object.values(costs[key]).length > 0) {
        if (key === "paymentSpread") return;
        var toNumber = parseInt(Object.values(costs[key])) > 0 ? parseFloat(Object.values(costs[key])[0]).toFixed(2).replace(".", ",") : null;
        if (!toNumber) return;
        var extraContent = "";
        if (key === "services" && !!allDataFields) {
          if (typeof allDataFields["services_choices"] !== "undefined" && allDataFields["services_choices"].length > 0 && typeof allDataFields["services_delivery_popup_start_btn"] !== "undefined" && !!allDataFields["services_delivery_popup_start_btn"] && typeof allDataFields["services_delivery_popup_content"] !== "undefined" && !!allDataFields["services_delivery_popup_content"]) {
            for (var i = 0; i < allDataFields["services_choices"].length; i++) {
              if (typeof allDataFields["services_choices"][i]["title"] !== "undefined" && typeof allDataFields["services_choices"][i]["extra_popup"] !== "undefined" && allDataFields["services_choices"][i]["title"] === Object.keys(costs[key])[0] && allDataFields["services_choices"][i]["extra_popup"] === true) {
                extraContent = " *";
              }
            }
          }
        }

        // Push the remaining items into the array
        tableData.push({
          title: Object.keys(costs[key])[0],
          total: "",
          value: "".concat(getPriceLabel(toNumber, scheme, costs[key]["type"], langCode, getPriceTotal(costs, scheme, langCode, false, false, true), true)).concat(extraContent)
        });
      }
    });

    // Add standard price as the first item
    var standardPrice = getStandardPrice(scheme, langCode, standardText);
    tableData.unshift(standardPrice);
  }
  return tableData;
};
var getTotalData = function getTotalData(costs, scheme, language, paymentSpread, spreadPrice) {
  var design = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var totalExVatText = arguments.length > 6 ? arguments[6] : undefined;
  var vatText = arguments.length > 7 ? arguments[7] : undefined;
  var totalInclVatText = arguments.length > 8 ? arguments[8] : undefined;
  var toBePaidNowText = arguments.length > 9 ? arguments[9] : undefined;
  var useVat = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : true;
  var customVat = arguments.length > 11 && arguments[11] !== undefined ? arguments[11] : null;
  var discount = arguments.length > 12 && arguments[12] !== undefined ? arguments[12] : null;
  var discountText = arguments.length > 13 && arguments[13] !== undefined ? arguments[13] : "Discount";
  var tableData = [];
  var totalExVAT = getPriceTotal(costs, scheme, language, false, false);
  if (design) {
    totalExVAT = getPriceTotal(costs.services, scheme, language, false, true);
  }
  if (!!paymentSpread) {
    totalExVAT += totalExVAT * spreadPrice / 100;
  }
  if (!!discount && !design && discount > 0) {
    totalExVAT -= discount;
    tableData.push({
      title: discountText,
      total: "",
      value: formatPrice(discount, scheme, language, true)
    });
  }
  if (!!totalExVAT) {
    tableData.push({
      title: "",
      total: totalExVatText,
      value: formatPrice(totalExVAT, scheme, language)
    });
  }
  var priceRowVAT = getKeyByValue(scheme, "materiaal", "VAT percentage");
  var percentageVAT;
  useVat ? !!customVat && !isNaN(customVat) ? percentageVAT = customVat : percentageVAT = scheme[priceRowVAT][language] * 1 : 0;
  var priceVAT = parseFloat(percentageVAT) > 0 ? totalExVAT * (percentageVAT / 100) : 0;
  tableData.push({
    title: "",
    total: vatText,
    value: formatPrice(priceVAT, scheme, language)
  });
  var totalIncVAT = totalExVAT + priceVAT;
  tableData.push({
    title: "",
    total: totalInclVatText,
    value: formatPrice(totalIncVAT, scheme, language)
  });
  var totalPayNow = !!paymentSpread ? totalIncVAT / 2 : totalIncVAT;
  tableData.push({
    title: "",
    total: toBePaidNowText,
    value: formatPrice(totalPayNow, scheme, language)
  });
  return tableData;
};
var getStandardPriceValue = function getStandardPriceValue(scheme, language) {
  var priceRowStandard = getKeyByValue(scheme, "materiaal", "Standard price");
  var standardPrice = parseInt(scheme[priceRowStandard][language]) || 0;
  return standardPrice;
};
var getStandardPrice = function getStandardPrice(scheme, language, standardText) {
  var standardPrice = getStandardPriceValue(scheme, language);
  return {
    title: parse(standardText),
    total: "",
    value: formatPrice(standardPrice, scheme, language)
  };
};
var getSpreadPrice = function getSpreadPrice(spreadPrice, costs, scheme, language) {
  var design = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var totalPrice = getPriceTotal(costs, scheme, language, false, design);
  var calculatePercentage = totalPrice * spreadPrice / 100;
  return formatPrice(calculatePercentage, scheme, language);
};
var getPrices = function getPrices(costs, scheme, language, paymentSpread, spreadPrice, formState) {
  var design = false;
  if (!!formState && formState.has("services")) {
    var services = formState.get("services");
    if (services.value === "design-only") {
      design = true;
    }
  }
  var totalExVAT = getPriceTotal(costs, scheme, language, false, design);
  if (!!paymentSpread) {
    totalExVAT += spreadPrice;
  }
  var priceRowVAT = getKeyByValue(scheme, "materiaal", "VAT percentage");
  var percentageVAT = scheme[priceRowVAT][language] * 1 || 0;
  var priceVAT = percentageVAT > 0 ? totalExVAT * (percentageVAT / 100) : 0;
  var totalIncVAT = Number.parseFloat(totalExVAT + priceVAT).toFixed(2);
  var totalPayNow = Number.parseFloat(!!paymentSpread ? totalIncVAT / 2 : totalIncVAT).toFixed(2);
  priceVAT = Number.parseFloat(priceVAT).toFixed(2);
  totalExVAT = Number.parseFloat(totalExVAT).toFixed(2);
  return {
    totalExVAT: totalExVAT,
    priceVAT: priceVAT,
    totalIncVAT: totalIncVAT,
    totalPayNow: totalPayNow
  };
};
var mainGetPrices = function mainGetPrices(costs, scheme, language, paymentSpread, spreadPrice, vatCalculation) {
  var totalExVAT = getPriceTotal(costs, scheme, language, false, false);
  if (!!paymentSpread) {
    totalExVAT += totalExVAT * spreadPrice / 100;
  }
  var priceRowVAT = getKeyByValue(scheme, "materiaal", "VAT percentage");
  var percentageVAT;
  var customVat = !!language ? language.customVat : null;
  vatCalculation ? !!customVat && !isNaN(customVat) ? percentageVAT = parseFloat(customVat) : percentageVAT = scheme[priceRowVAT][language] * 1 : 0;
  var priceVAT = percentageVAT > 0 ? totalExVAT * (percentageVAT / 100) : 0;
  var totalIncVAT = Number.parseFloat(totalExVAT + priceVAT).toFixed(2);
  var totalPayNow = Number.parseFloat(!!paymentSpread ? totalIncVAT / 2 : totalIncVAT).toFixed(2);
  priceVAT = Number.parseFloat(priceVAT).toFixed(2);
  totalExVAT = Number.parseFloat(totalExVAT).toFixed(2);
  return {
    totalExVAT: totalExVAT,
    priceVAT: priceVAT,
    totalIncVAT: totalIncVAT,
    totalPayNow: totalPayNow
  };
};
export { getPriceByField, getPriceTotal, formatPrice, getKeyByValue, getPriceDetails, getStandardPriceValue, getStandardPrice, getTotalData, getSpreadPrice, getPrices, mainGetPrices };