import forceValidation from "./forceValidation";
import getNextStep from "./getNextStep";
export default (function (event, props, pages, formValues, activePage, setActivePage, setPageClicked, setTouched, setShowPageValidationMsg) {
  event.preventDefault();
  var beforeNextPage = props.activePage;
  var isPageValidated = forceValidation(activePage, formValues, setShowPageValidationMsg, setTouched);
  if (!isPageValidated) return false;
  var nextPage = getNextStep(activePage, pages, formValues);
  if (beforeNextPage) {
    beforeNextPage(activePage, formValues, nextPage);
  }
  setActivePage(nextPage);
  setPageClicked(true);
  setShowPageValidationMsg(false);
  setActivePage(nextPage);
  setPageClicked(true);
  setShowPageValidationMsg(false);
});