var _getNextStep = function getNextStep(activePage, pages, formValues) {
  var nextPage = activePage + 1;
  var nextPageId = pages[activePage - 1];

  // if no next page
  if (!formValues[nextPageId]) {
    return false;
  }

  // if there is conditional login
  if (formValues[nextPageId].hideField === true) {
    nextPage = _getNextStep(nextPage);
  }
  return nextPage;
};
export default _getNextStep;